import React, {Component} from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import i18n from 'i18n-js'
import FormGroup from '@material-ui/core/FormGroup'
import Collapse from '@material-ui/core/Collapse'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'

import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'

import {ExpandMore, ExpandLess} from '@material-ui/icons'
import {getLocalTheme} from '../../../helpers/storageHelper'

const brandTheme = getLocalTheme()

const styles = theme => ({
  root: {
    padding: 0
  },
  listItem: {
    border: `1px solid ${theme.palette.action.disabled}`,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
  },
  brand: (brandTheme.select) ? brandTheme.select : {}
})

class MultipleOptionsField extends Component {
  constructor (props) {
    super(props)

    this.state = {
      collapse: false,
      showOptions: 'no',
      values: []
    }

    this.handleCollapse = this.handleCollapse.bind(this)
    this.handleShowOptions = this.handleShowOptions.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentWillMount () {
    const {options: {Id}, handleFieldSubmit, preSelectData} = this.props

    if (preSelectData) {
      this.setState({
        value: preSelectData
      })

      handleFieldSubmit({
        SowQuestionId: Id,
        SowAnswerIds: [preSelectData]
      })
    }
  }

  handleCollapse () {
    this.setState({
      collapse: !this.state.collapse
    })
  }

  handleShowOptions (event) {
    const { handleUserRequiredIDs, options } = this.props

    const showOptions = event.target.value === 'yes'

    this.setState({
      collapse: false,
      showOptions: event.target.value,
      values: (showOptions) ? this.state.values : []
    })

    const type = (showOptions) ? 'mandatory' : 'optional'
    handleUserRequiredIDs(type, options.Id)
  }

  handleChange (event) {
    let {values} = this.state
    const {options: {Id}, handleFieldSubmit} = this.props

    if (event.target.checked) {
      values.push(event.target.value)
    } else {
      let index = values.indexOf(event.target.value)
      values.splice(index, 1)
    }

    this.setState({values}, () => {
      let {values} = this.state

      values = values.map((value) => {
        return parseInt(value)
      })

      handleFieldSubmit({
        SowQuestionId: Id,
        SowAnswerIds: values
      })
    })
  }

  render () {
    const {classes, id, fieldId, options: {SowAnswers}, hide, preOptionsText, userRequiredOption} = this.props
    const {collapse, showOptions, values} = this.state

    const question = `questions.${id}`
    let icon = (collapse) ? <ExpandLess /> : <ExpandMore />

    const optionsQuestionText = (showOptions === 'yes') ? `${question}.userRequired` : `${question}.text`

    return (
      <div className={classNames({'hide': hide})}>
        {userRequiredOption && (
          <div>
            <FormLabel>{i18n.t(`${question}.text`)}</FormLabel>
            <RadioGroup
              name='userShowOptions'
              value={this.state.showOptions}
              onChange={this.handleShowOptions}
              row={true}
            >
              <FormControlLabel
                value='yes'
                control={<Radio id='option_yes' />}
                label={i18n.t('global.yes')}
              />
              <FormControlLabel
                value='no'
                control={<Radio id='option_no' />}
                label={i18n.t('global.no')}
              />
            </RadioGroup>
          </div>
        )}
        {showOptions === 'yes' && (
          <List className={classes.root}>
            <ListItem
              button
              onClick={this.handleCollapse}
              className={classNames(classes.listItem, {[classes.brand]: !!brandTheme.select})}
            >
              <ListItemText primary={i18n.t(optionsQuestionText, { defaultValue: i18n.t(`${question}.text`) })} />
              {icon}
            </ListItem>
            <Collapse in={collapse} timeout='auto'>
              {preOptionsText && <Typography>{preOptionsText}</Typography>}
              <FormGroup>
                {SowAnswers.map(({Id}) => {
                  const label = i18n.t(`${question}.answers.${Id}`)

                  return (<FormControlLabel
                    key={Id}
                    control={
                      <Checkbox
                        id={`${fieldId}_${label.replace(/\s+/g, '-').toLowerCase()}`}
                        checked={values.includes(Id.toString())}
                        onChange={this.handleChange}
                        value={Id.toString()}
                      />
                    }
                    label={label}
                  />)
                })}
              </FormGroup>
            </Collapse>
          </List>
        )}
      </div>
    )
  }
}

MultipleOptionsField.propTypes = {
  name: PropTypes.string,
  id: PropTypes.number.isRequired,
  fieldId: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  hide: PropTypes.bool,
  preOptionsText: PropTypes.string
}

export default withStyles(styles)(MultipleOptionsField)

import React from 'react'
import i18n from 'i18n-js'

import {CURRENT_COMPONENT} from '../../../constants'

import Link from '@material-ui/core/Link'
import Info from '@material-ui/icons/Info'
import withStyles from '@material-ui/core/styles/withStyles'
import {getLocalTheme} from '../../../helpers/storageHelper'

const brandTheme = getLocalTheme()

const styles = theme => ({
  faqLink: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  faqText: {
    marginLeft: theme.spacing(0.5)
  }
})

function FAQLink ({classes, updateTopLevelState, gtmEvent}) {
  let FAQIcon = <Info />
  if (brandTheme.faqIcon) {
    FAQIcon = <img src={brandTheme.faqIcon} alt='info' />
  }

  return <Link
    onClick={() => {
      updateTopLevelState({currentComponent: CURRENT_COMPONENT.HELP_FAQS})
      gtmEvent({eventAction: 'FAQs'})
    }}
    variant='body2' underline='always' className={classes.faqLink}>
    {FAQIcon}
    <span className={classes.faqText}>{i18n.t('faq.link-text')}</span>
  </Link>
}

export default withStyles(styles)(FAQLink)

import React, {Component} from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import i18n from 'i18n-js'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import CloudUpload from '@material-ui/icons/CloudUpload'
import Button from '../../Common/components/Button'
import withStyles from '@material-ui/core/styles/withStyles'

import {
  handleChange,
  getUploadStatusText,
  handleDelete
} from './actions'
import {getLocalTheme} from '../../../helpers/storageHelper'

const brandTheme = getLocalTheme()

const styles = theme => ({
  root: {
    margin: `${theme.spacing(1)}px 0`
  },
  input: {
    display: 'none'
  },
  button: {
    width: '100%'
  },
  btnLarge: {
    paddingTop: `${theme.spacing(2)}px`,
    paddingBottom: `${theme.spacing(2)}px`
  },
  files: {
    marginTop: `${theme.spacing(1)}px`
  },
  text: {
    paddingLeft: 12,
    paddingRight: 12,
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  chip: {
    marginRight: `${theme.spacing(1)}px`,
    marginBottom: `${theme.spacing(1)}px`
  },
  brand: brandTheme.fileUpload,
  brandDisabled: {
    ...brandTheme.fileUploadDisabled,
    opacity: 0.5,
    cursor: 'not-allowed',
    '&:hover': {
      background: 'none'
    }
  },
  brandChip: brandTheme.file
})

class FileField extends Component {
  constructor (props) {
    super(props)

    this.handleChange = handleChange.bind(this)
    this.handleDelete = handleDelete.bind(this)
    this.getUploadStatusText = getUploadStatusText.bind(this)
    this.state = {
      files: []
    }
  }

  render () {
    const {
      name,
      large,
      classes,
      documentData,
      addFileToQueue,
      removeFileFromQueue,
      files,
      documentData: {limit},
      typeId,
      typeTitle,
      ...other
    } = this.props

    let uploadStatus = (files) ? files.size : 0

    const className = classNames(
      classes.button,
      {[classes.btnLarge]: large},
      classes.brand,
      {[classes.brandDisabled]: (uploadStatus === limit)}
    )
    const colorProps = (!brandTheme.fileUpload) ? {color: 'primary'} : {}

    return (
      <div className={classes.root}>
        <input
          className={classes.input}
          id={name}
          name={name}
          multiple={true}
          type='file'
          onChange={this.handleChange}
          disabled={uploadStatus === limit}
        />
        <label htmlFor={name}>
          <Button
            {...colorProps}
            {...other}
            className={className}
          >
            <Typography {...colorProps} className={classes.text}>
              <CloudUpload />
              {i18n.t(`documents.cta.upload`, {limit: limit, type: typeTitle})}
            </Typography>
          </Button>
        </label>
        <div className={classes.files}>
          {files !== undefined && Array.from(files).map(([key, value]) => {
            const {blob: {name}} = value
            let label = name

            if (value.blob.name.length > 25) {
              const filename = name.substr(0, 25)
              const filetypeStart = name.lastIndexOf('.')
              const filetype = name.substr(filetypeStart, name.length - 1)
              label = `${filename}...${filetype}`
            }

            return <Chip
              className={classNames(classes.chip, classes.brandChip)}
              key={key}
              label={label}
              color='primary'
              variant='outlined'
              onDelete={() => this.handleDelete(key)}
            />
          })}
        </div>
      </div>
    )
  }
}

FileField.propTypes = {
  name: PropTypes.string.isRequired,
  large: PropTypes.bool
}

export default withStyles(styles)(FileField)

import React from 'react'

import {ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles'
import createTheme from '@material-ui/core/styles/createTheme'
import CssBaseline from '@material-ui/core/CssBaseline'
import blue from '@material-ui/core/colors/blue'
import {getLocalTheme} from '../../../helpers/storageHelper'

const defaultColor = 'rgba(0, 0, 0, 0.87)'

const brandTheme = getLocalTheme()

const theme = createTheme({
  palette: {
    primary: {
      main: brandTheme.primaryColor || blue[500]
    },
    secondary: {
      main: brandTheme.primaryColor || blue[500]
    },
    background: {
      default: brandTheme.backgroundColor || 'rgba(200, 200, 200, 0.75)'
    },
    text: {
      // primary: brandTheme.bodyText || defaultColor
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    h1: {
      fontSize: '1.8rem'
    },
    h2: {
      fontSize: '1.6rem'
    },
    h3: {
      fontSize: '1.2rem'
    },
    body2: {
      color: brandTheme.bodyText || defaultColor
    }
  },
  overrides: {
    MuiTypography: {
      h1: {
        color: brandTheme.headerColor || defaultColor
      },
      h2: {
        color: brandTheme.headerColor || defaultColor
      },
      h3: {
        color: brandTheme.headerColor || defaultColor
      },
      body1: {
        color: brandTheme.headerColor || defaultColor
      },
      paragraph: {
        margin: '16px 0'
      }
    },
    MuiLink: {
      underlineAlways: {
        color: brandTheme.linkColor || blue[500],
        cursor: 'pointer'
      }
    },
    MuiButton: {
      contained: {
        textTransform: 'initial',
        boxShadow: 'none',

        '&:active': {
          boxShadow: 'none'
        }
      }
    },
    MuiSelect: {
      root: {
        color: brandTheme.select.color
      },
      select: {
        '&:focus': {
          backgroundColor: 'transparent'
        },
        '&:not([multiple]) option': {
          backgroundColor: brandTheme.select.backgroundColor
        }
      },
      icon: {
        color: 'black',
        right: 15
      }
    },
    MuiSlider: {
      root: {
        width: '97%',
        margin: '20px auto 30px'
      },
      track: {
        height: 8
      },
      thumb: {
        height: 20,
        width: 20
      }
    },
    MuiPrivateNotchedOutline: {
      root: {
        borderRadius: (brandTheme.select.borderRadius !== undefined) ? brandTheme.select.borderRadius : 4
      }
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: brandTheme.select.borderColor
        }
      }
    },
    MuiFormLabel: {
      root: {
        color: brandTheme.headerColor
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: brandTheme.faqs.backgroundColor || 'white'
      }
    }
  }
})

function Theme ({children}) {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline>
        {children}
      </CssBaseline>
    </MuiThemeProvider>
  )
}

export default Theme

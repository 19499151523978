import React from 'react'
import classNames from 'classnames'
import i18n from 'i18n-js'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import blue from '@material-ui/core/colors/blue'

import '../limits.scss'
import {getLocalTheme} from '../../../helpers/storageHelper'

const brandTheme = getLocalTheme()

const styles = theme => ({
  root: {
    margin: `${theme.spacing(2)}px 0`
  },
  limitStats: {
    position: 'relative',
    width: 100,
    height: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  statsOutline: {
    stroke: (brandTheme.depositLimits.borderColor) ? `${brandTheme.depositLimits.borderColor}80` : blue['200']
  },
  statsBorder: {
    stroke: brandTheme.depositLimits.borderColor || blue['500']
  },
  value: {
    fontSize: '1.4rem',
    color: brandTheme.depositLimits.color || blue['500']
  },
  text: {
    width: '50%',
    textAlign: 'left',
    marginLeft: `${theme.spacing(2)}px`
  },
  header: {
    color: (brandTheme.headerColor) ? brandTheme.headerColor : `${theme.palette.text.primary}`,
    textAlign: 'center',
    lineHeight: 1.2
  }
})

function DepositLimits ({classes, percentage}) {
  let root = document.documentElement
  root.style.setProperty('--stroke-value', getStrokeValue(percentage))

  return (
    <Grid className={classes.root} container={true} justify='center' alignItems='center'>
      <div className={classes.limitStats}>
        <svg className='stat-container' x='0px' y='0px' width='100px' height='100px' viewBox='0 0 80 80'>
          <circle cx='40' cy='40' r='38' fill='transparent' strokeWidth='0.5' className={classes.statsOutline} />
          <circle className={classNames('circle', classes.statsBorder)} transform='rotate(90 40 40)'
            cx='40' cy='40' r='36' fill='transparent' strokeWidth='4' />
        </svg>
        <Typography variant='h1' color='primary' className={classes.value}>{`${percentage}%`}</Typography>
      </div>
      <Typography paragraph={true} className={classes.text}>{i18n.t('countdown.deposit-threshold')}</Typography>
    </Grid>
  )
}

function getStrokeValue (percentage) {
  return 227 - ((227 * percentage) / 100)
}

export default withStyles(styles)(DepositLimits)

import React from 'react'
import ReactDOM from 'react-dom'
import queryString from 'query-string'
import {getBrandTheme} from './helpers/storageHelper'

import TagManager from 'react-gtm-module'

const {location: {search}} = window
const {
  theme,
  loadGTM = true
} = queryString.parse(search, {
  arrayFormat: 'comma',
  parseNumbers: true,
  parseBooleans: true,
})

if (loadGTM) {
  TagManager.initialize({
    gtmId: 'GTM-KS87ZP6',
    dataLayerName: 'SOW_dataLayer'
  })
}

if (theme) {
  getBrandTheme(theme).then(() => {
    // importing App after promise ensures theme is saved before any App functions run
    const App = require('./modules/App/containers/App').default
    ReactDOM.render(<App />, document.getElementById('root'))
  })
} else {
  const App = require('./modules/App/containers/App').default
  ReactDOM.render(<App />, document.getElementById('root'))
}

import React, {Component} from 'react'
import PropTypes from 'prop-types'
import i18n from 'i18n-js'
import classNames from 'classnames'

import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import ExpandMore from '@material-ui/icons/ExpandMore'
import withStyles from '@material-ui/core/styles/withStyles'
import {getLocalTheme} from '../../../helpers/storageHelper'

const brandTheme = getLocalTheme()
const {mainContainer: {backgroundColor}} = brandTheme

const styles = theme => ({
  label: {
    background: backgroundColor || 'white',
    paddingRight: `${theme.spacing(1)}px`
  },
  select: {
    marginBottom: `${theme.spacing(2)}px`
  },
  hide: {
    display: 'none'
  }
})

// TODO: implement one common SelectField (Questionnaire/Documents)
class SelectField extends Component {
  constructor (props) {
    super(props)

    this.state = {
      value: ''
    }

    this.handleChange = this.handleChange.bind(this)
  }

  componentWillMount () {
    const {options: {Id}, handleFieldSubmit, preSelectData} = this.props

    if (preSelectData) {
      this.setState({
        value: preSelectData
      })

      handleFieldSubmit({
        SowQuestionId: Id,
        SowAnswerIds: [preSelectData]
      })
    }
  }

  handleChange = (event) => {
    const {handleFieldSubmit} = this.props

    this.setState({
      value: event.target.value
    }, () => {
      const {value} = this.state
      handleFieldSubmit(value)
    })
  }

  render () {
    const {classes, name, options, preSelectData} = this.props
    const label = 'Select Document'
    return (
      <FormControl variant='outlined' className={classNames(classes.select, {[classes.hide]: preSelectData})}>
        <InputLabel className={classes.label} ref={ref => { this.InputLabelRef = ref }} htmlFor={name}>
          {label}
        </InputLabel>
        <Select
          native
          value={this.state.value}
          onChange={this.handleChange}
          IconComponent={ExpandMore}
          input={
            <OutlinedInput
              labelWidth={label.length * 7.5}
              name={name}
              id={name}
            />
          }
        >
          <option value=''></option>
          {Array.from(options).map(([key]) => {
            return <option value={key} key={key}>{i18n.t(`documents-sections.${key}.title`)}</option>
          })}
        </Select>
      </FormControl>
    )
  }
}

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  options: PropTypes.object.isRequired
}

export default withStyles(styles)(SelectField)

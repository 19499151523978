import React from 'react'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import MUIButton from '@material-ui/core/Button'
import {getLocalTheme} from '../../../helpers/storageHelper'

const brandTheme = getLocalTheme()
const styles = theme => ({
  root: {
    textTransform: 'initial',
    borderRadius: 3,
    boxShadow: theme.shadows[0]
  },
  contained: {
    boxShadow: theme.shadows[0],
    '&:active': {
      boxShadow: theme.shadows[0]
    },
    '&:disabled': brandTheme.buttonDisabled
  },
  brand: brandTheme.button
})

function Button (props) {
  const {classes, children, className, ...other} = props

  return (
    <MUIButton
      className={classNames(classes.root, classes.contained, classes.brand, className)}
      {...other}
    >
      {children}
    </MUIButton>
  )
}

export default withStyles(styles)(Button)

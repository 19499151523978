import React, {PureComponent} from 'react'
import classNames from 'classnames'
import i18n from 'i18n-js'
import Typography from '@material-ui/core/Typography'
// import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'

import Wrapper from '../../Common/components/Wrapper'
import AppBar from '../../Common/components/AppBar'
import Button from '../../Common/components/Button'
import FAQLink from '../../Common/components/FAQLink'
// import Counter from '../components/Counter'
import GPDepositStats from '../../DepositLimits/containers/DepositLimits'
import WithdrawFunds from '../../Common/components/WithdrawFunds'

import {APPROVAL_TYPES, CURRENT_COMPONENT, MIN_PERCENTAGE, AML_STATUS, POST_MESSAGE} from '../../../constants'
import {getErrorMessage} from '../../../handlers/errorHandler'
import Link from '@material-ui/core/Link'
import {getLocalTheme} from '../../../helpers/storageHelper'
import {htmlToReactParser, getSOWType} from '../../../helpers/stringHelper'
import {postMessage} from '../../../handlers/messageHandler'

const brandTheme = getLocalTheme()

const styles = theme => ({
  root: {
    textAlign: 'center',
  },
  countdown: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  counterContainer: {
    margin: `${theme.spacing(1)}px 0`
  },
  counterText: {
    lineHeight: 1
  },
  brandColor: {
    color: brandTheme.headerColor
  },
  pendingText: {
    '& .brand-name': {
      color: brandTheme.headerColor,
      textTransform: 'capitalize'
    },
    '& .counter': {
      color: brandTheme.headerColor
    }
  },
  logoutBtn: {
    marginBottom: `${theme.spacing(2)}px`
  },
  logoutLink: {
    marginTop: `${theme.spacing(2)}px`
  }
})

class Countdown extends PureComponent {
  constructor (props) {
    super(props)
    this.getComponentFromKYC = this.getComponentFromKYC.bind(this)
    this.getComponentFromAML = this.getComponentFromAML.bind(this)
    this.setNextComponent = this.setNextComponent.bind(this)
    this.isFurtherActionRequired = this.isFurtherActionRequired.bind(this)
  }

  setNextComponent () {
    const {gracePeriod} = this.props
    if (gracePeriod >= 0) {
      this.getComponentFromKYC()
    } else {
      this.getComponentFromAML()
    }
  }

  getComponentFromAML () {
    const {amlStatus, updateTopLevelState, pendingApprovalTypes, resubmitSOWQ} = this.props

    switch (amlStatus) {
      case AML_STATUS.SOW_QUESTIONNAIRE:
        if ((pendingApprovalTypes.length > 0 && pendingApprovalTypes.includes(APPROVAL_TYPES.QUESTIONNAIRE)) ||
        resubmitSOWQ) {
          updateTopLevelState({currentComponent: CURRENT_COMPONENT.QUESTIONNAIRE})
        } else {
          updateTopLevelState({currentComponent: CURRENT_COMPONENT.DOCUMENTS})
        }
        break
      case AML_STATUS.SOW_DOCUMENTS:
      case AML_STATUS.SO_FUNDS:
        updateTopLevelState({currentComponent: CURRENT_COMPONENT.DOCUMENTS})
        break
      case AML_STATUS.NOT_BLOCKED:
      default:
        updateTopLevelState({currentComponent: CURRENT_COMPONENT.COUNTDOWN})
        break
    }
  }

  async getComponentFromKYC () {
    const {handleError, pendingApprovalTypes, updateTopLevelState, resubmitSOWQ} = this.props
    try {
      if (pendingApprovalTypes.includes(APPROVAL_TYPES.QUESTIONNAIRE) || resubmitSOWQ) {
        updateTopLevelState({currentComponent: CURRENT_COMPONENT.QUESTIONNAIRE})
      } else {
        updateTopLevelState({currentComponent: CURRENT_COMPONENT.DOCUMENTS})
      }
    } catch (error) {
      handleError(getErrorMessage(error))
    }
  }

  isFurtherActionRequired () {
    const {gracePeriodType, amlStatus, pendingApprovalTypes, processingApprovalTypes, resubmitSOWQ} = this.props
    const sowType = getSOWType(gracePeriodType, amlStatus, resubmitSOWQ)

    switch (sowType) {
      case 'questionnaire':
        return pendingApprovalTypes.includes(APPROVAL_TYPES.QUESTIONNAIRE) ||
          pendingApprovalTypes.includes(APPROVAL_TYPES.ID) ||
          pendingApprovalTypes.includes(APPROVAL_TYPES.ADDRESS) ||
          resubmitSOWQ
      case 'documents':
        return pendingApprovalTypes.includes(APPROVAL_TYPES.DOCUMENTS) &&
          !processingApprovalTypes.includes(APPROVAL_TYPES.DOCUMENTS)
      case 'funds':
        return pendingApprovalTypes.includes(APPROVAL_TYPES.FUNDS) &&
          !processingApprovalTypes.includes(APPROVAL_TYPES.FUNDS)
      default:
        return false
    }
  }

  renderCountdown () {
    const {
      classes,
      gracePeriod,
      gracePeriodType,
      amlStatus,
      updateTopLevelState,
      depositPercentage,
      brand,
      gtmEvent,
      license
    } = this.props

    // let counter1 = 0
    // let counter2 = 0

    // if (gracePeriod > 0) {
    //   counter1 = (gracePeriod > 9) ? gracePeriod.toString().substr(0, 1) : 0
    //   counter2 = (gracePeriod > 9) ? gracePeriod.toString().substr(1, 1) : gracePeriod
    // }

    const blockType = (gracePeriod > 0) ? 'grace-period' : 'blocked'
    const textOptions = (gracePeriod > 0) ? {count: gracePeriod} : {brand: brand}

    const furtherUserActionRequired = this.isFurtherActionRequired()
    let showDepositStat = (depositPercentage >= MIN_PERCENTAGE)
    showDepositStat = false // temporarily disable deposit stats

    return (
      <div className={classes.countdown}>
        <Typography variant='h1' color='primary' gutterBottom={true} className={classNames(
          {[classes.brandColor]: !!brandTheme.headerColor}
        )}>
          {i18n.t('countdown.action-required')}
        </Typography>
        {/* {gracePeriod > 0 &&
          <Grid container={true} justify='center' alignItems='center' className={classes.counterContainer}>
            <Counter color='primary' inline={true}>{counter1}</Counter>
            <Counter color='primary' inline={true}>{counter2}</Counter>
            <Typography
              variant='body1'
              color='primary'
              inline={true}
              align='left'
              className={classNames(
                classes.counterText,
                {[classes.brandColor]: !!brandTheme.headerColor}
              )}
            >
              {htmlToReactParser(i18n.t('countdown.days-remaining', {count: gracePeriod}))}
            </Typography>
          </Grid>
        } */}

        {!furtherUserActionRequired && <React.Fragment>
          <Typography paragraph={true}>{i18n.t('countdown.sow-processing')}</Typography>
          {amlStatus !== AML_STATUS.NOT_BLOCKED &&
            <Button
              onClick={() => {
                postMessage(POST_MESSAGE.LOGOUT)
                gtmEvent({eventAction: 'Logout'})
              }}
              variant='contained'
              color='primary'
              className={classes.logoutBtn}
            >
              {i18n.t('countdown.logout')}
            </Button>
          }
        </React.Fragment>}

        {furtherUserActionRequired &&
          <React.Fragment>
            {!showDepositStat && <Typography paragraph={true} className={classes.pendingText}>
              {htmlToReactParser(
                i18n.t(`countdown.sow-pending.${getSOWType(gracePeriodType, amlStatus)}.${blockType}`, textOptions)
              )}
            </Typography>}

            {showDepositStat && <GPDepositStats percentage={depositPercentage} />}

            <Button onClick={this.setNextComponent} variant='contained' color='primary'>
              {i18n.t('countdown.cta')}
            </Button>

            {amlStatus !== AML_STATUS.NOT_BLOCKED &&
              <Link
                className={classes.logoutLink}
                onClick={() => {
                  postMessage(POST_MESSAGE.LOGOUT)
                  gtmEvent({eventAction: 'Logout'})
                }}
                variant='body2'
                underline='always'
              >
                {i18n.t('countdown.logout')}
              </Link>
            }

            <Typography paragraph={true}>{i18n.t('countdown.footer')}</Typography>
          </React.Fragment>
        }
        <WithdrawFunds amlStatus={amlStatus} gtmEvent={gtmEvent} license={license} />
        <FAQLink updateTopLevelState={updateTopLevelState} gtmEvent={gtmEvent} />
      </div>
    )
  }

  render () {
    const {classes, amlStatus, depositPercentage, updateTopLevelState, debug, gtmEvent} = this.props
    let key = 'loading'
    let renderCountdown = <div />

    if (depositPercentage !== -1) {
      key = 'countdown'
      renderCountdown = this.renderCountdown()
    }

    return (
      <React.Fragment>
        <AppBar position='sticky'
          title={i18n.t('countdown.title')}
          amlStatus={amlStatus}
          updateTopLevelState={updateTopLevelState}
          debug={debug}
          gtmEvent={gtmEvent}
        />
        <Wrapper className={classes.root} useTransitionGroup={true} transitionKey={key}>
          {renderCountdown}
        </Wrapper>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(Countdown)

const axios = require('axios')
export async function getBrandTheme (theme) {
  const {data: brandTheme} = await axios.get(theme, {responseType: 'json'})
  sessionStorage.setItem('brandTheme', JSON.stringify(brandTheme))
  return brandTheme
}

export function getLocalTheme () {
  const brandTheme = (sessionStorage.getItem('brandTheme')) ? JSON.parse(sessionStorage.getItem('brandTheme')) : {}

  return {
    ...brandTheme.generic,
    mainContainer: (brandTheme.mainContainer) ? brandTheme.mainContainer : {},
    button: (brandTheme.button) ? brandTheme.button : {},
    buttonDisabled: (brandTheme.buttonDisabled) ? brandTheme.buttonDisabled : {},
    select: (brandTheme.select) ? brandTheme.select : {},
    slider: {
      track: (brandTheme.slider && brandTheme.slider.track) ? brandTheme.slider.track : {},
      thumb: (brandTheme.slider && brandTheme.slider.thumb) ? brandTheme.slider.thumb : {},
    },
    topBar: (brandTheme.topBar) ? brandTheme.topBar : {},
    counter: (brandTheme.counter) ? brandTheme.counter : {},
    statusLabel: (brandTheme.statusLabel) ? brandTheme.statusLabel : {},
    fileUpload: (brandTheme.fileUpload) ? brandTheme.fileUpload : {},
    fileUploadDisabled: (brandTheme.fileUploadDisabled) ? brandTheme.fileUploadDisabled : {},
    file: (brandTheme.file) ? brandTheme.file : {},
    depositLimits: (brandTheme.depositLimits) ? brandTheme.depositLimits : {},
    faqs: (brandTheme.faqs) ? brandTheme.faqs : {}
  }
}
